<template>
    <div class="court-com-court-senect">
        <van-dropdown-menu>
            <van-dropdown-item v-model="value" :options="courtListCul" @change="change">

            </van-dropdown-item>
        </van-dropdown-menu>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            venueId: [Number, String]
        },
        data() {
            return {
                courtList: [],
                state: false,
                value: null,
            };
        },
        methods: {
            pageInit(){
                this.courtListInit()
            },
            async courtListInit(){
                let res = await this.courtApi.courtList()
                if(!res || !res.venueList) return
                this.courtList = res.venueList || []
            },
            change(val){
                this.$emit('change', val)
            }
        },
        created() {
            this.pageInit()
        },
        components: {},
        computed: {
            courtListCul(){
                let {courtList: list} = this
                let rd = []
                for(let i in list){
                    let {id: value, venueName: text} = list[i]
                    value = Number(value)
                    rd.push({
                        ...list[i],
                        value,
                        text
                    })
                }
                console.log(this._common.deepCopy(rd))
                return rd
            },
            courtSelected(){
                // let {courtList: list, venueId: value} = this
                // let key = 'venueId'
                // let rd = {}, listData = {}
                // for(let i in list){
                //     let {picList} = list[i]
                //     let {data} = this._common.selected({list: picList, value, key})
                //     if(data) {
                //         rd = data
                //         listData = list[i]
                //         break
                //     }
                // }
                // if(rd.venueId == this.venueId){
                //     rd = {
                //         ...rd,
                //         ...listData
                //     }
                //     delete rd.picList
                // }
                // return rd


                let {courtList: list, value} = this
                let {data} = this._common.selected({list, value, key: 'id'})
                return data || {}

            },
        },
        watch: {
            venueId: {
                handler(val){
                    this.value = Number(val)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .court-com-court-senect{
        .van-dropdown-menu__bar{
            height: 20px;
            width: 300px;
            margin: 0px auto;
        }
        .van-ellipsis{
            font-size: 10px;
        }
        .van-cell{
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .van-cell__title{
            white-space: nowrap;
            span{
                font-size: 10px;
            }
        }
        .van-popup--top{
            width: 300px;
            left: 0px;
            right: 0px;
            margin: 0px auto;
        }
    }
</style>