<template>
    <div class="page-court-list">
        <div class="content-date">
            <van-tabbar v-model="dayValue" :fixed="false">
                <van-tabbar-item v-for="v in dateList" :key="v.day">
                    <div class="day-body text-center">
                        <div class="day-con flex-center">
                            <span class="text-mini">{{v.day}}</span>
                        </div>
                        <div class="day-con flex-center">
                            <span class="text-mini-b">{{v.month}}.{{v.date}}</span>
                        </div>
                    </div>
                </van-tabbar-item>
            </van-tabbar>
        </div>
        <div class="list-title">
            <div class="text text-center">
                <courtSelect ref="courtSelect" :venueId="venueId" @change="venueIdChange"></courtSelect>
            </div>
        </div>
        <div class="list-content flex-center">
            <courtTimeList ref="timeListCom" :list="courtTimeListCul" @change="selectedChange" mode="more"></courtTimeList>
        </div>
        
        <div class="order-params-box">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>定时预定参数</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="paramseOpen">设置</el-button>
                </div>
                <div v-for="(v, i) in orderParams" :key="i" class="order-par-item flex nowrap">
                    <div class="i-tit flex">
                        <span class="text-mini">{{i | orderParamsText}}</span>
                    </div>
                    <div class="i-con flex">
                        <span class="text-mini">{{v}}</span>
                    </div>
                </div>
                <div class="order-par-item flex nowrap pre-order-timeout" v-if="remindTimeText">
                    <div class="i-tit flex">
                        <span class="text-mini bold">预定倒计时：</span>
                    </div>
                    <div class="i-con flex">
                        <span class="text-mini bold">{{remindTimeText}}</span>
                    </div>
                </div>
                <div class="order-par-item flex btn">
                    <el-button class="start-btn" @click="countdownClear" v-if="preTimtoue">
                        <span class="text-mini">取消</span>
                    </el-button>
                    <el-button class="start-btn" @click="preOrderStart">
                        <span class="text-mini">开始</span>
                    </el-button>
                </div>
            </el-card>
        </div>
        <div class="order-params-box immediate">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>选择订场</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="paramseOpen">设置</el-button> -->
                </div>
                <div class="order-par-item" v-for="v in immediateOrder" :key="v.fun">
                    <div class="i-tit flex">
                        <el-button class="start-btn" @click="orderControl(v)">
                            <span class="text-mini">{{v.name}}</span>
                        </el-button>
                    </div>
                    <div class="i-con flex text-left" v-if="v.des">
                        <span class="text-mini-bb sub-text-color">{{desText(v.des)}}</span>
                    </div>
                </div>
                <div class="order-par-item card-price">
                    <div class="i-tit flex">
                        <el-input v-model="courtPrice" placeholder="会员价格"></el-input>
                    </div>
                    <div class="i-con flex text-left">
                        <span class="text-mini-bb sub-text-color">会员价格</span>
                    </div>
                </div>
            </el-card>
        </div>
        <orderParams ref="orderParams" :timeList="singleTimeList" @confirm="paramsConfirm"></orderParams>
        <moreOrderParams ref="moreOrderParams" @confirm="moreOrderConfirm" :selected="courtTimeSelected"></moreOrderParams>
        <order-list ref="orderList"></order-list>
    </div>
</template>
<script>
    import courtSelect from '../components/courtSelect.vue'
    import courtTimeList from '../components/courtTimeList.vue'
    import orderParams from '../components/orderParams.vue'
    import moreOrderParams from '../components/moreOrderParams.vue'
    import courtTool from '../tool/court'

    export default {
        name: '',
        props: {
        },
        filters: {
            orderParamsText(val){
                // startTime, endTime, appointmentTime, duration, quantity
                switch(val){
                    case 'startTime':
                        return '开始时间'
                    case 'endTime':
                        return '结束时间'
                    case 'appointmentTime':
                        return '预约时间'
                    case 'duration':
                        return '预定时长'
                    case 'quantity':
                        return '预定数量'
                    case 'bookerName':
                        return '联系人名'
                    case 'bookerContact':
                        return '联系电话'
                }
            }
        },
        data() {
            return {
                courtTool,
                venueId: this.$route.params.venueId,
                maxOrderDate: 8,
                orderTime: {
                    hour: 8,
                    minute: 0,
                    second: 0
                },
                courtList: [],
                dayValue: 0,
                courtTimeList: [],
                orderParams: {},
                courtTimeSelected: {},
                immediateOrder: [
                    {name: '直接订场', fun: 'orderSelect', des: '请先选择要预定的场地 =>'},
                    {name: '时间订场', fun: 'orderByParams', des: '将根据开始和结束时间以及预定时长、预定数量预定'},
                    {name: '多个场地', fun: 'moreCourt',  des: 'moreDes'},
                    {name: '订单', fun: 'orderListOpen'},
                ],
                payWay: 3, // 2 -- 微信支付 3 -- 会员卡支付
                bookWay: 0, // 不清楚意思
                bookPurpose: 4, // 不清楚意思
                remarks: '',
                bookerName: '', // 预定人
                bookerContact: '',
                courtPrice: 100,
                clientCardId: 257,
                orderRemindTime: null, //预定剩余时间
                preTimtoue: null,
                moreOrderTime: 0,

            };
        },
        methods: {
            async pageInit(){
                // await this.courtListInit()
                this.paramsInit()
            },
            async courtListInit(){
                let res = await this.courtApi.courtList()
                if(!res || !res.venueList) return
                this.courtList = res.venueList || []
            },
            async courtTimeListLoad(){
                let {venueId, queryDate} = this.timeListLoadWatch
                this.selectedClear()
                let res = await this.courtApi.courtTimeList({venueId, queryDate})
                if(!res) return
                this.courtTimeList = res
                this.orderCourtList
            },
            venueIdChange(venueId){
                if(!venueId) return
                this._routeHand.goto({name: 'court-list', params: {venueId}, type: 'replace'})
            },
            paramsConfirm(val){
                val.appointmentTime = this.courtTool.formatAppTime(val.appointmentTime)
                this.orderParams = val
            },
            async paramsInit(){
                await this._common.nextTick.call(this)
                this.paramsConfirm(this.$refs.orderParams.initParamsGet())
            },
            paramseOpen(){
                this.$refs.orderParams.open()
            },
            selectedChange(val){
                this.courtTimeSelected = val
            },
            selectedClear(){
                this.courtTimeSelected = {}
                if(this.$refs.timeListCom) this.$refs.timeListCom.selectedClear()
            },
            orderControl(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            getAvailableCourt(opt){
                let {startTime: start, endTime: end, timeList: list, duration} = opt || {}
                start = this.courtTool.parseTime(start)
                end = this.courtTool.parseTime(end)
                let data = []
                for(let i in list){
                    if(this.courtTool.isDisabled(list[i])) continue
                    let {startTime, endTime} = list[i]
                    startTime = this.courtTool.parseTime(startTime)
                    endTime = this.courtTool.parseTime(endTime)
                    if(startTime >= start && endTime <= end) data.push(list[i])
                }
                return this.createOrderParams({list: data, duration})
                // return data
            },
            createOrderParams(opt){
                let {list, duration} = opt || {}
                let rd = [], data = []
                for(let i in list){
                    let tem = rd[rd.length - 1]
                    if(!tem){
                        rd.push(list[i])
                    }else{
                        let {startTime, endTime} = list[i]
                        let {startTime: start, endTime: end} = tem
                        if(startTime == end) rd.push(list[i])
                        else rd = []
                    }
                    if(this.courtTool.getAmountDuration(rd) >= duration){
                        data.push(this.singleParams(rd, duration))
                        rd = []
                    }
                    
                }
                return data
                
            },
            singleParams(list){
                let {venueName} = this.$refs.courtSelect.courtSelected
                let {venueId, queryDate: bookDate} = this.timeListLoadWatch
                let {bookPurpose, payWay, remarks, bookWay, clientCardId} = this
                let {siteName, siteId} = list[0] || {}
                let {bookerContact, bookerName} = this.orderParams
                let duration = this.courtTool.getAmountDuration(list)
                let data = {
                    bookDate,
                    startEndTime: this.courtTool.startEndTime(list),
                    payAmount: this.courtPrice * duration,
                    siteName,
                    siteId,
                    bookPurpose,
                    payWay,
                    venueId,
                    venueName,
                    bookWay,
                    timeIds: this.courtTool.timeIds(list),
                    bookerContact,
                    bookerName,
                    remarks,
                    clientCardId
                }
                return data
            },
            orderSelect(){
                let data = Object.values(this.courtTimeSelected)
                if(!Object.keys(data).length) return
                let params = this.singleParams(data)
                this.orderAction(params)
            },
            async orderAction(data){
                let res = await this.courtApi.submitOrder(data)
                return res
            },
            async preOrderStart(){
                let {appointmentTime: time} = this.orderParams
                await this.preOrderCountdown(time)
                let list = this._common.deepCopy(this.orderCourtList)
                let res = await this.loopOrderStart(list)
                if(!res){
                    this._errorHand.hand({code: 'order fail'})
                }else{
                    this._common.toastOpen({
                        message: this._errorCode.common['order success'],
                        type: 'success'
                    })
                }
                
            },
            async loopOrderStart(list){
                list = list || []
                if(!list.length) return
                let data = list.shift()
                if(!data) return
                let res = await this.orderAction(data)
                if(res) return res
                await this._common.settimeout(1000)
                return this.loopOrderStart(list)
            },
            async preOrderCountdown(time){
                this.countdownClear()
                let t = parseInt((new Date()).getTime() / 1000)
                let preT = parseInt((new Date(time)).getTime() / 1000)
                let det = preT - t
                if(det <= 0) return true
                this.orderRemindTime = det * 1000
                let inT = 1000
                return new Promise(res => {
                    this.preTimtoue = setInterval(() => {
                        if(this.orderRemindTime <= 0){
                            this.countdownClear()
                            return res()
                        }
                        this.orderRemindTime -= inT
                    }, inT);
                })

            },
            countdownClear(){
                if(!this.preTimtoue) return
                clearInterval(this.preTimtoue)
                this.orderRemindTime = null
                this.preTimtoue = null
            },
            orderListOpen(){
                this.$refs.orderList.open()
            },
            moreCourt(){
                this.$refs.moreOrderParams.open()
            },
            desText(des){
                if(this[des] === undefined) return des
                return this[des]
            },
            async moreOrderConfirm(opt){
                let {list, orderTime} = opt
                let params = []
                for(let i in list){
                    params.push(this.singleParams(list[i]))
                }
                console.log(params)
                if(!params.length) return
                // await this.waitMoreTime(orderTime)
                await this.moreOrderAction(params)
                this.selectedClear()
            },
            async moreOrderAction(params){
                let data = params.pop()
                if(!data) return
                console.log(this._common.deepCopy(data))
                let t = parseInt((new Date()).getTime() / 1000)
                await this.orderAction(data)
                let remind = (1000 - (parseInt((new Date()).getTime() / 1000) - t))
                console.log(remind)
                await this._common.settimeout(remind)
                return this.moreOrderAction(params)
            },
            async waitMoreTime(time){
                time = ((new Date(time)).getTime() - (new Date()).getTime())
                this.moreOrderTime = time
                let t = 1000
                setInterval(() => {
                    if(this.moreOrderTime <= 0) return
                    this.moreOrderTime -= t
                }, t);
                await this._common.settimeout(time)
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            courtSelect,
            courtTimeList,
            orderParams,
            moreOrderParams
        },
        computed: {
            moreOrderTimeCul(){
                let {moreOrderTime: time} = this
                time = parseInt(time / 1000)
                let min = parseInt(time / 60)
                let sec = time % 60
                let hour = parseInt(min / 60)
                min = min % 60
                if(hour < 10) hour = '0' + hour
                if(min < 10) min = '0' + min
                if(sec < 10) sec = '0' + sec
                return `${hour}:${min}:${sec}`
            },
            moreDes(){
                if(this.moreOrderTime <= 0) return '先选择场地'
                return `${this.moreOrderTimeCul}后开始预定`
            },
            remindTimeText(){
                let {orderRemindTime: t} = this
                if(!t) return null
                let second = parseInt(t / 1000)
                let hour = parseInt(second / 60 / 60)
                let day = parseInt(hour / 24)
                hour = hour % 24
                let minute = parseInt(second / 60) % 60
                second = second % 60
                if(hour < 10) hour = `0${hour}`
                if(minute < 10) minute = `0${minute}`
                if(second < 10) second = `0${second}`
                let text = ''
                if(day > 0) text += `${day}天`
                text += `${hour}:${minute}:${second}`
                return text
                // if(Number(hour) > 0) text += `${hour}:`
                // if(Number(minute) > 0) text += `${minute}:`
                // text += `${second}`
            },
            courtTimeListCul(){
                let {courtTimeList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {siteTimeList: timeList, siteName} = list[i]
                    list[i].siteTimeList = this.courtTool.parseTimeList(list[i])
                }
                return list
            },
            singleTimeList(){
                let {courtTimeListCul: list} = this
                let index = null, length = 0
                for(let i in list){
                    let {siteTimeList} = list[i]
                    if(siteTimeList.length > length) length = siteTimeList.length, index = i
                }
                if(index === null) return []
                return list[index].siteTimeList
            },
            queryDate(){
                let {dayValue: index, dateList} = this
                let data = dateList[index]
                data = data || {}
                return `${data.year}-${data.month}-${data.date}`
            },
            courtSelected(){
                let {courtList: list, venueId: value} = this
                let key = 'venueId'
                let rd = {}, listData = {}
                for(let i in list){
                    let {picList} = list[i]
                    let {data} = this._common.selected({list: picList, value, key})
                    if(data) {
                        rd = data
                        listData = list[i]
                        break
                    }
                }
                if(rd.venueId == this.venueId){
                    rd = {
                        ...rd,
                        ...listData
                    }
                    delete rd.picList
                }
                return rd

            },
            dateList(){
                let data = []
                let {maxOrderDate} = this
                let timestamp = (new Date()).getTime()
                let dayT = 24 * 60 * 60 * 1000
                for(let i = 0; i < maxOrderDate; i++){
                    let t = dayT * i + timestamp
                    let date = new Date(t)
                    let tem = {
                        year: date.getFullYear(),
                        month: date.getMonth() + 1,
                        date: date.getDate(),
                        day: this._common.getDayText(date.getDay())
                    }
                    tem = this._common.serverTimeFormat(tem)
                    data.push(tem)
                }
                return data
            },
            timeListLoadWatch(){
                // let {courtSelected, queryDate} = this
                // let {venueId} = courtSelected || {}
                let {venueId, queryDate} = this
                return {venueId, queryDate}
            },
            courtTimeListWatch(){
                let {startTime, endTime, appointmentTime, duration, quantity} = this.orderParams
                let {courtTimeListCul} = this
                return {startTime, endTime, appointmentTime, duration, quantity, courtTimeListCul}
            },
            orderCourtList(){
                
                let {courtTimeListCul: list} = this
                let {startTime, endTime, duration} = this.orderParams
                let orderList = []
                for(let i in list){
                    let {siteTimeList: timeList, id} = list[i]
                    orderList = orderList.concat(this.getAvailableCourt({timeList, startTime, endTime, duration}))
                }
                return orderList

            }
        },
        watch: {
            courtTimeListWatch: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                },
                deep: true,
                immediate: true
            },
            timeListLoadWatch: {
                handler(val, oldVal){
                    if(!val.venueId || this._common.isSameObject(val, oldVal)) return
                    this.courtTimeListLoad()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(val){
                    this.venueId = this.$route.params.venueId
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.countdownClear()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-court-list{
        position: relative;
        padding-bottom: 40px;
        .content-date{
            width: 100%;
            margin-bottom: 20px;
        }
        .list-title{
            width: 100%;
            .pic{
                height: 120px;
                overflow: hidden;
                width: 50%;
                margin: 0px auto;
                img{
                    width: 100%;
                }
            }
        }
        .van-tabbar-item__text{
            height: 100%;
        }
        .van-tabbar{
            height: 30px;
            z-index: 2;
        }
        .day-body{
            display: flex;
            align-content: space-around;
            flex-wrap: wrap;
            height: 100%;
            .day-con{
                width: 100%;
            }
        }
        .order-params-box.immediate{
            right: auto;
            left: 0px;
            .i-tit{
                margin-bottom: 5px;
            }
        }
        .order-params-box{
            position: absolute;
            right: 0px;
            top: 50px;
            .el-card{
                width: 200px;
            }
            .el-card__header{
                padding: 6px 10px;
                span{
                    font-size: 12px;
                }
            }
            .el-card__body{
                padding: 10px;
                .order-par-item+ .order-par-item{
                    margin-top: 10px;
                }
                .start-btn{
                    height: 20px;
                    padding: 0px;
                    width: 50px;
                    float: right;
                }
                .order-par-item.btn{
                    justify-content: flex-end;
                }
                .order-par-item{
                    .i-tit{
                        margin-right: 10px;
                    }
                }
                .card-price{
                    input{
                        height: 20px;
                    }
                }
            }
        }
        span[class^='text-']{
            text-align: left;
            line-height: 15px;
            height: auto;
        }
        .pre-order-timeout{
            color: #f83838;
        }
    }
</style>