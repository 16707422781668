import common from '@/global/js/commonFun'

export default {
    _common: new common(),
    parseTime(time, opt){
        let {orginal} = opt || {}
        if(isNaN(Number(time))){
            time = time || ''
            time = time.split(':')
            time =  Number(time[0])
        }
        if(time == 0 && !orginal) time = 24
        return time
    },
    getDuration(opt){
        let {startTime, endTime} = opt || {}
        if(isNaN(Number(startTime))) startTime = this.parseTime(startTime)
        if(isNaN(Number(endTime))) endTime = this.parseTime(endTime)
        return endTime - startTime
    },
    parseTimeList(opt){
        let {siteTimeList: list, siteName} = opt
        list = list || []
        for(let i in list){
            let duration = this.getDuration(list[i])
            list[i].duration = duration
            list[i].siteName = siteName
        }
        return list
    },
    isDisabled(val){
        let {outDate, booked} = val || {}
        return outDate || booked
    },
    getAmountDuration(list){
        let duration = 0
        for(let i in list){
            duration += this.getDuration(list[i])
        }
        return duration
    },
    startEndTime(list){
        let start = null, end = null
        for(let i in list){
            let {startTime, endTime} = list[i]
            let st = this.parseTime(startTime)
            let et = this.parseTime(endTime)
            if(start === null || st < this.parseTime(start)) start = startTime
            if(end === null || et > this.parseTime(end)) end = endTime
        }
        return `${start}-${end}`
    },
    timeIds(list){
        let ids = []
        for(let i in list){
            ids.push(list[i].id)
        }
        return ids
    },
    formatAppTime(time){
        let t = time
        if(!this._common.isDate(time)) t = new Date(time)
        console.log(t)
        if(!this._common.isDate(t)) return time
        let year = t.getFullYear(), month = t.getMonth() + 1, date = t.getDate(), hour = t.getHours(), minute = t.getMinutes(), second = t.getSeconds()
        if(month < 10) month = `0${month}`
        let data = this._common.serverTimeFormat({
            year, month, date, hour, minute, second
        })
        return `${data.year}-${data.month}-${data.date} ${data.hour}:${data.minute}:${data.second}`
    }
}