<template>
    <div class="page-com-court-more-order">
        <van-dialog v-model="state" @confirm="confirm" :showCancelButton="true">
            <div class="m-order-body">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>预定信息</span>
                    </div>
                    <div class="order-par-item" v-for="(v, i) in orderList" :key="i">
                        <div class="sit-name-box">
                            <span>{{i}}</span> --
                            <span class="order-time" v-for="s in v" :key="s.id">{{s.startTime}}-{{s.endTime}}</span>
                        </div>
                    </div>
                    <!-- <div class="order-par-item">
                        <el-date-picker
                        prefix-icon="icon-c"   
                        clear-icon="icon-cl"
                        
                        v-model="orderTime"
                        type="datetime"
                        placeholder="预约时间"
                        :default-value="appoDefaultTime">
                        </el-date-picker>
                    </div> -->
                </el-card>
            </div>
        </van-dialog>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            selected: {
                type: Object,
                default(){ return {} }
            }
        },
        data() {
            return {
                orderTime: null,
                state: false
            };
        },
        methods: {
            confirm(){
                let {orderTime} = this
                this.$emit('confirm', {
                    list: this.orderList,
                    orderTime
                })
            },
            open(){
                this.state = true
            }
        },
        created() {},
        components: {},
        computed: {
            appoDefaultTime(){
                let t = new Date()
                return t
            },
            orderList(){
                let {selected} = this
                let rd = {}
                for(let i in selected){
                    let {siteId, siteName} = selected[i]
                    if(!rd[siteName]) rd[siteName] = []
                    rd[siteName].push(selected[i])
                }
                for(let i in rd){
                    this._common.sortData(rd[i], (v1, v2) => {
                        let {startTime: v1Start} = v1, {startTime: v2Start} = v2
                        v1Start = Number(v1Start.split(':')[0])
                        v2Start = Number(v2Start.split(':')[0])
                        return v1Start < v2Start
                    })
                }
                return rd
            }
        },
        watch: {
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-com-court-more-order{
        .m-order-body{
            .order-par-item+ .order-par-item{
                margin-top: 10px;
            }
            .order-time+ .order-time{
                margin-left: 10px;
            }
            .el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 100%;
            }
            .el-input__inner{
                padding: 0px 10px;
                height: 30px;
                line-height: normal;
            }
        }
    }
</style>