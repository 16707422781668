<template>
    <div class="page-com-court-order-params">
        <van-dialog v-model="state" @confirm="confirm" :showCancelButton="true">
            <div class="params-body">
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">开始时间</span>
                    </div>
                    <div class="par-content">
                        <el-time-select
                        popper-class="reset-popper-z-index"
                        prefix-icon="icon-c"
                        clear-icon="icon-cl"
                        placeholder="起始时间"
                        v-model="startTime"
                        :picker-options="timeStartOpt">
                        </el-time-select>
                    </div>
                </div>
                
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">结束时间</span>
                    </div>
                    <div class="par-content">
                        <el-time-select
                        popper-class="reset-popper-z-index"
                        prefix-icon="icon-c"
                        clear-icon="icon-cl"
                        placeholder="结束时间"
                        v-model="endTime"
                        :picker-options="timeEndOpt">
                        </el-time-select>
                    </div>
                </div>
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">预约时间</span>
                    </div>
                    <div class="par-content">
                        <el-date-picker
                        prefix-icon="icon-c"   
                        clear-icon="icon-cl"
                        popper-class="reset-popper-z-index"
                        v-model="appointmentTime"
                        type="datetime"
                        placeholder="预约时间"
                        :default-value="appoDefaultTime">
                        </el-date-picker>
                    </div>
                </div>
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">预定时长</span>
                    </div>
                    <div class="par-content">
                        <el-select v-model="duration" placeholder="预定时长" popper-class="reset-popper-z-index">
                            <el-option
                            v-for="item in durationList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                                {{item.name}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">预定数量</span>
                    </div>
                    <div class="par-content">
                        <el-select v-model="quantity" placeholder="预定数量" popper-class="reset-popper-z-index" disabled>
                            <el-option
                            v-for="item in quantityList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                                {{item.name}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">联系人名</span>
                    </div>
                    <div class="par-content">
                        <el-input v-model="bookerName" placeholder="联系人"></el-input>
                    </div>
                </div>
                <div class="params-item flex">
                    <div class="par-title nowrap">
                        <span class="text-mini">联系电话</span>
                    </div>
                    <div class="par-content">
                        <el-input v-model="bookerContact" placeholder="联系人"></el-input>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            timeList: {
                type: Array,
                default(){ return [] }
            }
        },
        data() {
            return {
                appData: new this.appDataClass({key: 'court'}),
                state: false,
                startTime: null,
                endTime: null,
                appointmentTime: null,
                duration: 2,
                quantity: 1,
                bookerName: '乐乐',
                bookerContact: '15010193341',
                quantityList: [
                    {value: 1, name: 1},
                    {value: 2, name: 2},
                    {value: 3, name: 3},
                    {value: 4, name: 4},
                ],
                durationList: [
                    {value: 1, name: '1小时'},
                    {value: 2, name: '2小时'},
                    {value: 3, name: '3小时'},
                    {value: 4, name: '4小时'},
                ],
                startOrderTime: 8
            };
        },
        methods: {
            initParamsGet(){
                let {startTime, endTime, appointmentTime, duration, quantity, bookerName, bookerContact} = this.appData.getItem('orderParams') || {}
                startTime = startTime || this.defaultStartTime
                endTime = endTime || this.defaultEndTime
                bookerName = bookerName || this.defaultBookerName
                bookerContact = bookerContact || this.defaultBookerContact
                appointmentTime = this.appointmentTimeCheck(appointmentTime)
                duration = duration || 2
                quantity = quantity || 1
                return {startTime, endTime, appointmentTime, duration, quantity, bookerName, bookerContact}
            },
            open(){
                this.optionsInit()
                this.state = true
            },
            optionsInit(){
                let val = this.initParamsGet()
                for(let i in val){
                    this[i] = val[i]
                }
            },
            confirm(){
                this.saveData()
                let {startTime, endTime, appointmentTime, duration, quantity, bookerName, bookerContact} = this
                this.$emit('confirm', {
                    startTime, endTime, appointmentTime, duration, quantity, bookerName, bookerContact
                })
            },
            saveData(){
                let {startTime, endTime, appointmentTime, duration, quantity, bookerName, bookerContact} = this
                let items = {}
                if(startTime) items.startTime = startTime
                if(endTime) items.endTime = endTime
                if(appointmentTime) items.appointmentTime = appointmentTime
                if(duration) items.duration = duration
                if(quantity) items.quantity = quantity
                if(bookerName) items.bookerName = bookerName
                if(bookerContact) items.bookerContact = bookerContact
                let data = {
                    orderParams: JSON.stringify(items)
                }
                this.appData.setItem(data)
            },
            appointmentTimeCheck(time){
                let appDate = new Date(time)
                if(!this._common.isDate(appDate) || isNaN(appDate.getFullYear())) return this.defaultAppointmentTime
                let appTimestamp = parseInt(appDate.getTime() / 1000)
                let timeNow = parseInt((new Date()).getTime() / 1000)
                if(appTimestamp <= timeNow) return this.defaultAppointmentTime
                return time
            },
        },
        created() {
        },
        components: {},
        computed: {
            defaultBookerName(){
                return '乐乐'
            },
            defaultBookerContact(){
                return '15010193341'
            },
            timeStartOpt(){
                let {startTime: start} = this.timeList[0] || {}
                let {endTime: end} = this.timeList[this.timeList.length - 1] || {}
                if(start == '00:00') start = '24:00'
                if(end == '00:00') end = '24:00'
                let step = `01:00`
                return { start, end, step }
            },
            timeEndOpt(){
                let {startTime} = this
                if(!startTime) return {}
                let start = startTime.split(':')
                start = Number(start[0]) + 1
                let {endTime: end} = this.timeList[this.timeList.length - 1] || {}
                end = end.split(':')
                end = Number(end[0])
                if(end == 0) end = '24'
                if(end <= start) end = start + 1
                if(start < 10) start = `0${start}`
                if(end < 10) end = `0${end}`
                start = `${start}:00`
                end = `${end}:00`
                let step = '01:00'
                if(start == '00:00') start = '24:00'
                if(end == '00:00') end = '24:00'
                return { start, end, step }
            },
            defaultStartTime(){
                return `16:00`
            },
            defaultEndTime(){
                return `20:00`
            },
            defaultAppointmentTime(){
                let t = new Date()
                let hour = t.getHours()
                let year = t.getFullYear()
                let month = t.getMonth() + 1
                let date = t.getDate()
                if(hour >= this.startOrderTime) date++
                return `${year}-${month}-${date} ${this.startOrderTime}:0:0`
            },
            appoDefaultTime(){
                let t = new Date()
                return t
            },
        },
        watch: {
            // timeList: {
            //     handler(val){
            //         if(!val || !val.length) return
            //         this.open()
            //     },
            //     deep: true,
            //     immediate: true
            // },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-com-court-order-params{
        .params-body{
            padding: 20px;
            .params-item+ .params-item{
                margin-top: 20px;
            }
            .params-item{
                align-items: center;
                @titleWidth: 50px;
                .par-title{
                    width: @titleWidth;
                }
                .par-content{
                    width: calc(100% - @titleWidth);
                    input{
                        width: 100%;
                    }
                }
                .el-select{
                    width: 100%;
                }
                .el-date-editor.el-input, .el-date-editor.el-input__inner{
                    width: 100%;
                }
                .el-input__inner{
                    padding: 0px 10px;
                    height: 30px;
                    line-height: normal;
                }
            }
        }
    }
</style>