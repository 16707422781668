<template>
    <div class="court-com-court-time-list flex">
        <div class="list-item" v-for="v in listCul" :key="v.id">
            <div class="item-title cell-item flex-center" :class="{'time-text-box': !v.id}">
                <span class="text-mini">{{v.siteName}}</span>
            </div>
            <div class="item-time-box">
                <div class="time-item" v-for="(s, k) in v.siteTimeList" :key="s.id">
                    <div class="time-text-box cell-item flex-center" v-if="s.startTimeText && s.endTimeText">
                        <div class="t-i a flex-center" v-if="k == 0">
                            <span class="text-mini">{{s.startTimeText}}</span>
                        </div>
                        <div class="t-i b flex-center">
                            <span class="text-mini">{{s.endTimeText}}</span>
                        </div>
                    </div>
                    <div class="c-box cell-item flex-center cursor-pointer" v-else :class="{'disabled': s.booked || s.outDate, 'selected': selected[s.id]}" @click="selectCourt(s)">
                        <span class="text-mini">{{s.bookPrice | siteText(s)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            list: {
                type: Array,
                default(){ return [] }
            },
            mode: {
                type: String,
                default: 'normal'
            }
        },
        filters: {
            siteText(v, val){
                let {outDate, booked} = val || {}
                if(!outDate && !booked) return v
                return '不可预定'
            }
        },
        data() {
            return {
                selected: {}
            };
        },
        methods: {
            selectCourt(val){
                let fun = `${this.mode}Select`
                if(!this[fun]) return
                this[fun](val)
            },
            moreSelect(val){
                val = val || {}
                if(!val.id) return
                if(!this.checkNormal(val)) return
                let {outDate, booked} = val || {}
                if(outDate || booked) return false
                if(outDate) return false
                this.$set(this.selected, val.id, val)
                this.selected[val.id] = val
            },
            normalSelect(val){
                val = val || {}
                if(!val.id) return
                if(!this.check(val)) return
                let {outDate, booked} = val || {}
                if(outDate || booked) return false
                if(outDate) return false
                this.$set(this.selected, val.id, val)
                this.selected[val.id] = val
            },
            checkNormal(val){
                let {selected} = this
                selected = this._common.deepCopy(selected)
                let {id} = val || {}
                if(selected[id]){
                    this.cancel(val)
                    return false
                } 
                return true
            },
            check(val){
                let {selected} = this
                selected = this._common.deepCopy(selected)
                let {siteId} = val || {}
                if(!this.checkNormal(val)) return false
                // if(selected[id]){
                //     this.cancel(val)
                //     return false
                // } 
                for(let i in selected){
                    let {siteId: sId} = selected[i]
                    if(sId != siteId){
                        selected = {}
                        break
                    }
                }
                this.selected = selected
                return true
            },
            cancel(val){
                let {id} = val || {}
                this.$delete(this.selected, id)
            },
            timeListGet(list){
                // let {list} = this
                let time = []
                let {siteTimeList = []} = list[0] || {}
                for(let i in siteTimeList){
                    let {startTime: startTimeText, endTime: endTimeText} = siteTimeList[i]
                    time.push({
                        startTimeText,
                        endTimeText
                    })
                }
                let tem = [
                    {
                        siteName: '',
                        siteTimeList: time
                    },
                ]
                return tem
            },
            selectedClear(){
                this.selected = {}
            },
        },
        created() {},
        components: {},
        computed: {
            timeList(){
                let {list} = this
                let time = []
                let {siteTimeList = []} = list[0] || {}
                for(let i in siteTimeList){
                    let {startTime: startTimeText, endTime: endTimeText} = siteTimeList[i]
                    time.push({
                        startTimeText,
                        endTimeText
                    })
                }
                let tem = [
                    {
                        siteName: '',
                        siteTimeList: time
                    },
                ]
                return tem
            },
            listCul(){
                // let tem = this._common.deepCopy(this.timeList)
                let {list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {siteTimeList} = list[i]
                    siteTimeList.push({
                        bookPrice: '',
                        startTime: ' ',
                        endTime: ' ',
                    })
                    list[i].siteTimeList = siteTimeList
                }
                let tem = this.timeListGet(list)
                console.log(this._common.deepCopy(tem.concat(list)))
                return tem.concat(list)
            },
        },
        watch: {
            selected: {
                handler(val){
                    this.$emit('change', val)
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .court-com-court-time-list{
        .list-item{
            width: 60px;
            border-right: 1px solid #DDDDDD;
            .cell-item{
                color: #555;
                width: 100%;
                height: 24px;
                border-bottom: 1px solid #ddd;
            }
            .time-text-box{
                position: relative;
                background: #DDDDDD;
                flex-wrap: wrap;
                align-content: space-between;

                .t-i{
                    z-index: 2;
                    position: absolute;
                    width: 100%;
                    height: 50%;
                }
                .t-i.a{
                    top: -6px;
                }
                .t-i.b{
                    bottom: -6px;
                }
            }
            .selected{
                background: #FA9E55;
                color: #fff;
            }
            .disabled{
                cursor: not-allowed;
                color: #ddd;
            }
        }
    }
</style>